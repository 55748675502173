// window.addEventListener("load", function () {
//   console.log("Liftoff.. 🚀");
// });
// ...


// async function postData(url = '', data = {}) {
//   // Default options are marked with *
//   const response = await fetch(url, {
//     method: 'POST', // *GET, POST, PUT, DELETE, etc.
//     mode: 'cors', // no-cors, *cors, same-origin
//     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//     credentials: 'same-origin', // include, *same-origin, omit
//     headers: {
//       'Content-Type': 'application/json'
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     redirect: 'follow', // manual, *follow, error
//     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//     body: JSON.stringify(data) // body data type must match "Content-Type" header
//   });
//   return response.json(); // parses JSON response into native JavaScript objects
// }

// Lightbox
// Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
//   element.onclick = (e) => {
//     e.preventDefault();
//     basicLightbox.create(`<img src="${element.href}">`).show();
//   };
// });


window.addEventListener('load', function () {

  // Dark-mode favicon
  // (function(){

  //   if (!window.matchMedia) {
  //     return;
  //   }

  //   var current = document.querySelectorAll('head > link[rel="icon"][media]');

  //   current.forEach(function(icon, i) {
  //     var match = window.matchMedia(icon.media);
  //     function swap() {
  //         if (match.matches) {
  //             // current[0].remove();
  //             current[0].disabled = true;
  //             // current = document.head.appendChild(icon);
  //             document.head.appendChild(icon);
  //         }
  //     }
  //     match.addEventListener('change', swap);
  //     swap();
  //   });

  // })();


  // Handle video hero - don't rely on autoplay attribute and handle play/pause on idle tab..
  if (!!document.querySelector(".video--autoplay-loop")) {

    // get a reference to a <video> element
    const video = document.querySelector(".video--autoplay-loop");

    // attempt to call play() and catch if it fails
    video.play();
    // .then(() => {
    //   console.log('Autoplay success!');
    // }).catch((error) => {
    //   console.warn('Autoplay error', error);
    // });

    const onVisibilityChange = () => {
      // const video = document.querySelector(".hero__video video");
      return document.hidden
        ? video.pause()
        : video.play();
    }

    document.addEventListener("visibilitychange", onVisibilityChange);
  }


  Handlebars.registerHelper('if_eq', function(a, b, opts) {
    if (a == b) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
  });

  var notificationOffsetTop = (!!document.querySelector(".site-header")) ? 'top-36' : '';

  var notificationTemplate =
  `<!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <div x-data="{ isOpen: false }" x-show="isOpen" @open-{{ type }}-notification.window="isOpen = true" @close-{{ type }}-notification.window="isOpen = false; document.querySelector('.notification').remove()" class="notification z-20 ${notificationOffsetTop} fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
    <div x-show="isOpen" x-transition:enter="transform ease-out duration-300 transition" x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0" x-transition:leave="transition ease-in duration-100" x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0" class="max-w-sm w-full frosted-glass shadow-lg rounded-lg pointer-events-auto bg-gray-900 bg-opacity-75">
      <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              {{#if_eq type 'success' }}
                <!-- Heroicon name: check-circle -->
                <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              {{/if_eq}}
              {{#if_eq type 'error' }}
                <!-- Heroicon name: exclamation -->
                <svg class="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              {{/if_eq}}
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm leading-5 font-medium text-white">
                {{ title }}
              </p>
              <p class="mt-1 text-sm leading-5 text-gray-400">
                {{ body }}
              </p>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button @click="isOpen = false" class="inline-flex text-gray-400 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150">
                <!-- Heroicon name: x -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`;

  var notificationTemplate = Handlebars.compile(notificationTemplate);

  // if there's a newsletter signup form on the page
  if (!!document.querySelector(".newsletter-signup")) {

    var form = document.querySelector('.newsletter-signup form');
    var fields = {};

    form.querySelectorAll('[name]:not([type=hidden])').forEach(function (field) {
      fields[field.name] = field;
    });

    var onload = function (e) {
      if (e.target.status === 200) {

        // console.log("Success:", JSON.parse(e.target.response));

        // var firstName = document.getElementById('firstname').value;
        // var company = document.getElementById('company').value;

        // successMessage.innerHTML = 'Hej <span class="font-semibold">{{ firstname }}</span>, tak for interessen!<br>Din ansøgning er nu sendt og du vil høre nærmere fra mig omkring online butik for {{ company }}.<br><br>Med venligste hilsen Jimmy Rittenborg.'.replace('{{ firstname }}', firstName).replace('{{ company }}', company);

        document.body.insertAdjacentHTML('beforeend', notificationTemplate({ type: "success", title: "Du er blevet tilmeldt!", body: "Vi giver dig besked så snart vi har nyt på programmet." }));

        setTimeout(function(){
          var openErrorEvent = new Event("open-success-notification");
          window.dispatchEvent(openErrorEvent);
        }, 0);

        for (var key in fields) {
            if (!fields.hasOwnProperty(key)) continue;
            fields[key].value = '';
            fields[key].classList.remove('input-error');
        }

      } else {

        // console.log("Error:", JSON.parse(e.target.response));

        // document.body.insertAdjacentHTML('beforeend', notificationTemplate({ type: "error", title: "Der opstod en fejl", body: "En fejl gjorde desværre at vi ikke kunne tilmelde dig. Prøv venligst igen senere." }));

        // setTimeout(function(){
        //   var openErrorEvent = new Event("open-error-notification");
        //   window.dispatchEvent(openErrorEvent);
        // }, 0);

        handleError(JSON.parse(e.target.response));
      }
    };


    // Displays all error messages and adds 'error' classes to the form fields with
    // failed validation.
    var handleError = function (response) {
      var errors = [];
      for (var key in response) {
          if (!response.hasOwnProperty(key)) continue;
          // Array.prototype.push.apply(errors, response[key]);
          errors.push(response[key]);
          if (fields.hasOwnProperty(key)) {
            fields[key].classList.add('input-error');
            fields[key].value = errors[0];
          };
      }
      // errorMessage.innerHTML = errors.join('<br>');

      // errorMessage.innerHTML = errors[0];

      var errorMessage = '';

      for (var error in errors) {
        errorMessage += errors[error] + '. ';
      }


      document.body.insertAdjacentHTML('beforeend', notificationTemplate({
        type: "error",
        title: "Der opstod en fejl",
        body: (errorMessage !== '') ? "Vi kunne desværre ikke tilmelde dig. " + errorMessage : "Vi kunne desværre ikke tilmelde dig. Prøv venligst igen senere."
      }));

      setTimeout(function(){
        var openErrorEvent = new Event("open-error-notification");
        window.dispatchEvent(openErrorEvent);
      }, 0);

    }


    var getToken = function (e, callback) {
      var token_request = new XMLHttpRequest();
      var request_path = 'gettoken';

      token_request.onreadystatechange = function() {
        var token_fields = e.target.querySelectorAll('input[name="csrf_token"]');

        if (this.status === 200 && this.readyState === 4) {
          var token = JSON.parse(this.response).token;
          for(var i = 0; i < token_fields.length; i++) {
            token_fields[i].value = token;
          }
        }
      };

      token_request.open('GET', request_path);
      token_request.send();

      callback(callback);
    }


    var submit = function (e) {
      e.preventDefault();

      var closeErrorEvent = new Event("close-error-notification");
      var closeSuccessEvent = new Event("close-success-notification");
      window.dispatchEvent(closeErrorEvent);
      window.dispatchEvent(closeSuccessEvent);

      getToken(e, function(){
        var request = new XMLHttpRequest();
        var formData = new FormData(e.target);
        request.open('POST', '/mailchimp');

        // request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
        // request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        request.onload = onload;

        request.send(formData);
        // Remove all 'error' classes of a possible previously failed validation.
        // for (var key in fields) {
        //     if (!fields.hasOwnProperty(key)) continue;
        //     fields[key].classList.remove('error');
        // }
      });

    };

    form.addEventListener('submit', submit);

    form.querySelector('[name]:not([type=hidden]').addEventListener('focus', (event) => {
      var target = event.target;
      target.classList.remove('input-error')
      target.value = '';
    });

    // $.ajax({
    //   type: 'POST',
    //   // url: 'https://your-account.us5.list-manage.com/subscribe/post-json?u=69872cf6a3984c3b3330e6500&id=c1ee0ff5b5&c=?',
    //   url: 'https://discotekin.us2.list-manage.com/subscribe/post-json?u=a6bc7aa63ff02d7cc8fa8998c&id=31599f2322&c=?',
    //   data: $('#mc-embedded-subscribe-form').serialize(),
    //   cache: false,
    //   dataType: 'json',
    //   contentType: "application/json; charset=utf-8",
    //   error: (error) => {
    //     console.log('Error', error);
    //   },
    //   success: (res) => {
    //     console.log('Success');
    //   }
    // });
  }

});









// Init PhotoSwipe
var initPhotoSwipeFromDOM = function(gallerySelector) {

  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  var parseThumbnailElements = function(el) {
      var thumbElements = el.querySelectorAll('figure'), //el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item;

      for(var i = 0; i < numNodes; i++) {

          figureEl = thumbElements[i]; // <figure> element

          // include only element nodes
          if(figureEl.nodeType !== 1) {
              continue;
          }

          // console.log(figureEl.parentNode);
          // linkEl = figureEl.children[0]; // <a> element
          linkEl = figureEl.parentNode; // <a> element

          size = linkEl.getAttribute('data-size').split('x');

          // create slide object
          item = {
              src: linkEl.getAttribute('href'),
              w: parseInt(size[0], 10),
              h: parseInt(size[1], 10)
          };



          if(figureEl.children.length > 1) {
              // <figcaption> content
              item.title = figureEl.children[1].innerHTML;
          }

          if(linkEl.children.length > 0) {
              // <img> thumbnail element, retrieving thumbnail url
              item.msrc = linkEl.children[0].getAttribute('src');
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
      }

      return items;
  };

  // find nearest parent element
  var closest = function closest(el, fn) {
      return el && ( fn(el) ? el : closest(el.parentNode, fn) );
  };

  // triggers when user clicks on thumbnail
  var onThumbnailsClick = function(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : e.returnValue = false;

      var eTarget = e.target || e.srcElement;

      // find root element of slide
      var clickedListItem = closest(eTarget, function(el) {
          return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
      });

      if(!clickedListItem) {
          return;
      }

      // find index of clicked item by looping through all child nodes
      // alternatively, you may define index via data- attribute
      // var clickedGallery = clickedListItem.parentNode,
      var clickedGallery = clickedListItem.closest(gallerySelector),
          // childNodes = clickedListItem.parentNode.childNodes,
          childNodes = clickedGallery.querySelectorAll('figure'),
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

      for (var i = 0; i < numChildNodes; i++) {
          if(childNodes[i].nodeType !== 1) {
              continue;
          }

          if(childNodes[i] === clickedListItem) {
              index = nodeIndex;
              break;
          }
          nodeIndex++;
      }



      if(index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe( index, clickedGallery );
      }
      return false;
  };

  // parse picture index and gallery index from URL (#&pid=1&gid=2)
  var photoswipeParseHash = function() {
      var hash = window.location.hash.substring(1),
      params = {};

      if(hash.length < 5) {
          return params;
      }

      var vars = hash.split('&');
      for (var i = 0; i < vars.length; i++) {
          if(!vars[i]) {
              continue;
          }
          var pair = vars[i].split('=');
          if(pair.length < 2) {
              continue;
          }
          params[pair[0]] = pair[1];
      }

      if(params.gid) {
          params.gid = parseInt(params.gid, 10);
      }

      return params;
  };

  var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
      var pswpElement = document.querySelectorAll('.pswp')[0],
          gallery,
          options,
          items;

      items = parseThumbnailElements(galleryElement);

      // define options (if needed)
      options = {

          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute('data-pswp-uid'),

          getThumbBoundsFn: function(index) {
              // See Options -> getThumbBoundsFn section of documentation for more info
              var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                  pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                  rect = thumbnail.getBoundingClientRect();

              return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
          }

      };

      // PhotoSwipe opened from URL
      if(fromURL) {
          if(options.galleryPIDs) {
              // parse real index when custom PIDs are used
              // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
              for(var j = 0; j < items.length; j++) {
                  if(items[j].pid == index) {
                      options.index = j;
                      break;
                  }
              }
          } else {
              // in URL indexes start from 1
              options.index = parseInt(index, 10) - 1;
          }
      } else {
          options.index = parseInt(index, 10);
      }

      // exit if index not found
      if( isNaN(options.index) ) {
          return;
      }

      if(disableAnimation) {
          options.showAnimationDuration = 0;
      }

      // Pass data to PhotoSwipe and initialize it
      gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();
  };

  // loop through all gallery elements and bind events
  var galleryElements = document.querySelectorAll( gallerySelector );

  for(var i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i+1);
      galleryElements[i].onclick = onThumbnailsClick;
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  var hashData = photoswipeParseHash();
  if(hashData.pid && hashData.gid) {
      openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
  }
};

// execute above function
initPhotoSwipeFromDOM('.pswp-gallery');